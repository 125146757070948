.home-container {
    width: 100%;
    height:fit-content;
    position: relative;
    margin-bottom: 10vh;
}

// Home text containers and children

.home-text-container {
    background:none;
    height: 65vh;
    padding-top: 17.5vh;
}

.home-cloth-text-container {
    @include homeTextBoxes;
    text-align: left;
}

.home-wi-text-container {
    @include homeTextBoxes;
    text-align: right;
    left: 65vw;
    margin-top: 15vh;
    margin-bottom: 0;
}

@media screen and (max-width: $breakpoint-hamburger) {

    .home-container{
        top:0;
        margin-bottom: 12vh;
    }
    .home-text-container {
        height:auto;
    }

    .home-wi-text-container {
        @include mediaHomeTextGrid;
        left:0;
        grid-template-columns: 64% 36%;
        margin-top: 25vh;
        h1 {
            justify-content: flex-end;
        }
        p {
            text-align: left;
        }
    }

    .home-cloth-text-container {
        @include mediaHomeTextGrid;
        h1 {
            justify-content: left;
        }
        p {
            text-align: right;
        }
    }
  
    .footer-link-break-remove {
        display: none;
    }

}