@import "./config";
@import "./home";
@import "./slide";
@import "./aboutUs";
@import "./products";
@import "./projects";
@import "./downloads";
@import "./contactUs";


// App styling, 
// Position absolute so all child elements can become relative to it
#root {
    position: absolute;
    width: 100%;
    height: -webkit-fill-available;
}

// Nav bar styling
.navbar {
    background: rgba($color: white, $alpha: 0.85);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    font-size: 16pt;
    top: 13px;
    left:32px;
    right: 32px;
    border-bottom: 8px $greyColor solid;
    z-index: 5;
}

.logo {
    justify-self: start;
    cursor: pointer;
    height: 80%;
    width: 175px;
    display: flex;
    background: none;
}
.logo img{
    background: none;
}
.nav-item {
    background: none;
}
.nav-links {
    color: $goldColor;
    display: flex;
    font-weight: bold;
    font-family: 'Jaldi', sans-serif;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    background: none;
}
.nav-links:hover {
    background-color: $goldColor;
    color: white;
    border-radius: 0;
    transition: 250ms;
}
.nav-products {
    list-style: none;
    position: absolute;
    display: grid;
    background: none;
}
.prod-li{
    background: rgba($color: white, $alpha: 0.95);
    z-index: 5;
}
.product-links {
    @include subLinks
}
.product-links:hover {
    background-color: $goldColor;
    color: white;
    border-radius: 0;
    transition: 250ms;
}
.nav-WI {
    list-style: none;
    position: absolute;
    background: none;
    display: grid;
    left: 158px;
    top: 0;
}
.nav-menu {
    display: grid;
    background: none;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 73vw;
    justify-content: end;
}

.mobile-menu-toggle {
    display: none;
    color: $goldColor;
    background: none;
}
.mobile-menu-toggle img {
    background: none;
}

// Footer styling
.footer-container {
    font-size: 1em;
    border-top: 6px;
    border-top-color: $greyColor;
    border-top-style: solid;    
    border-bottom:rgba(255,255,255,0) 32px solid;
    position: relative;
    margin: 0px 32px;
    bottom: 32px;
    z-index: 2;
}

.footer-container-wrapper {
    padding-top: 8px;
    @include background-opacity-footer;
    width: auto;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
}

.footer-links {
    @include footer-containers;
    justify-content: left;
    padding-left: 8px;
}

.footer-copyright {
    @include footer-containers;
    justify-content: center;
    align-self: flex-end;
}

.footer-logo {
    @include footer-containers;
    text-align: right;
    justify-content: flex-end;
    align-content: flex-end;
}
.wi-logo-footer{
    background: none;
}
.wi-logo-footer img{
    background: none;
}
.footer-link-items {
    @include footer-items;
    text-align: left;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.footer-link-items button {
    background: none;
    border: none;
    font-size: 1em;
    cursor: pointer;
}

.footer-link-wrapper {
    display: flex;
    background: none;
    align-self: flex-end;
}

.footer-logo-wrapper {
    display: flex;
    background: none;
    align-self: flex-end;
}
.footer-copyright-wrapper{
    background: none;
}
.footer-copyright-items {
    @include footer-items;
    width: 200px;
    justify-content: center;
    text-align: left;
}

.website-rights {
    font-size: 0.75em;
    background: none;
}

.footer-logo-items {
    @include footer-items;
    margin-right: 50px;
    text-align: right;
}

.footer-link-items a{
        color: black;
        text-decoration: none;
        background: none;
}

.footer-link-break {
    @include linebreak;
}

.footer-link-break-remove {
    @include linebreak;
}

.designed-by {
    font-size: 0.8em;
    background: none;
}

.daisy-lake {
    text-decoration: none;
    color: black;
    background: none;
    &:hover{
        text-decoration: underline;
    }
}

// Media queries for both Nav and Footer
@media screen and (max-width: 1240px) {
    .footer-links {
        font-size: 1.2vw;
    }
}

@media screen and (max-width: $breakpoint-hamburger) {
  
    #app {
        height: fit-content;
    }
   .navbar {
        top: 0px;
        left: 0;
        right: 0;
        margin: 0px;
        font-size: 30pt;
        position: fixed;
        width: 101%;
        background: white;
    }
    .logo {
        float: left;
    }
    .mobile-menu-toggle {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-4vw, 40%);
        font-size: 2rem;
        cursor: pointer;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        align-items: center;
        top: 88px;
        left: -150%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: white;
        top: 88px;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 2;
        padding-top:10px;
        padding-bottom: 50vh;
    }

    .nav-links {
        text-align: center;
        align-items: center;
        margin-top: -10px;
        padding: 3vw 0;
        border-bottom: 4px solid $greyColor;
        min-width: 235px;
        width: 62.6vw;
        display: table;
    }
    ul {
        padding-inline-start: 0px;
    }

    .nav-products {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 105vh;
        position: absolute;
        margin-top: 10px;
        align-items: center;
        top: 98px;
        left: -150%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-products.active {
        position: absolute;
        align-items: center;
        background: white;
        top: -10px;
        left: 150%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        overflow: hidden;
    }
    .nav-WI {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 105vh;
        position: absolute;
        align-items: center;
        top: 0;
        left: -150%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-WI.active {
        position: absolute;
        align-items: center;
        top: 0;
        left: 150%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 3;
    }
    .product-links{
        border-left: 0px solid white;
        min-width: 235px;
        width: 62.6vw;
        padding: 0.3rem;
        font-size: 22pt;
        z-index: 5;
    }
    .media-remove-link {
        display: none;
    }

    .footer-logo {
        display: none;
    }

    .footer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0 10px 0;
        right: 0;
        background: white;
        border-bottom: none;
        position: relative;
        left: 0;
        bottom: 0;
        margin: 0px;
        height: 6vh;
        z-index:5;
    }

    .footer-container-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-copyright {
        @include media-960-box;
    }

    .footer-links {
        @include media-960-box;
        padding-bottom: 10px;
    }
    .footer-links button{
        font-size: 1.5vh;
    }

}
@media screen and (max-width: $breakpoint-tiny) {
    .nav-links{
        padding: 0.5vw 0;
        border-width: 2px;
    }

}