.contact-main-container {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 10vh;
}

.contact-wrapper {
    top: 120px;
    display: flex;
    position: inherit;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
}
.contact-text-container {
    display: flex;
    flex-direction: row;
    margin: 32px;
    height: fit-content;
    margin-bottom: 15vh;
    width: 50vw;
    background: rgba($color: $greyColor, $alpha: 0.9);
    color: white;
    display: grid;
    grid-template-columns: 40% 60%;
    h1, p {
        background: none;
        margin: 4vw 0px  4vw 80px;
    }
    p {
        margin-right: 80px;
        font-size: 10pt;
        font-weight: bold;
    }
    h1 {
        display: flex;
        font-size: 22pt;
        border-top: 6px solid $goldColor;
        border-bottom: 6px solid $goldColor;
        max-height: 110px;
        align-items: center;
    }

}

.contact-form-container {
    display: flex;
    flex-direction: row;
    margin: 32px 0 15vh 10px;
    height: fit-content;
    width: 40vw;
    // margin-bottom: 15vh;
    // margin-right: 0;
    // margin-left: 10px;
    top: 120px;
    background: rgba($color: $greyColor, $alpha: 0.9);
    color: white;
    h1 {
        background: none;
        margin: 4vw 0px  4vw 80px;
    }
    h1 {
        display: flex;
        font-size: 22pt;
        border-top: 6px solid $goldColor;
        border-bottom: 6px solid $goldColor;
        max-height: 110px;
        align-items: center;
    }
}

.submit-button {
    width: 15vw;
    height: 5vh;
    font-size: 1em;
    font-weight: bold;
    color: white;
    background: rgba($color: $goldColor, $alpha: 0.9);
    margin-bottom: 32px;
}

.contact-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin: 1vh 0 0 8vh;
    input {
        display: flex;
        flex-direction: row;
    }
    textarea {
        display: flex;
        flex-direction: row;
    }
} 

.contact-input {
    padding: 4px;
    padding-left: 5px;
}

.contact-email {
    color: white;
    background: none;
}

.checker {
    display: flex;
}

.contact-errors {
    color: red;
    font-size: 0.8em;
    margin: 0;
    margin-left: 7vw;
}

.contact-accepted {
    color: white;
    font-size: 0.8em;
    margin: 0;
    margin-left: 7vw;
}

@media screen and (max-width: 1200px) {
    .contact-form-container{
        display: grid;
    }
}

@media screen and (max-width: $breakpoint-hamburger) {
    .contact-main-container {
        position: relative;
        top: 88px;
    }

    .contact-wrapper {
        position: relative;
        top: 10px;
        display: flex;
        flex-direction: column;
    }

    .contact-text-container{
        display: initial;
        position: relative;
        padding-top: 2vw;
        width: 80vw;
        height: fit-content;
        margin:0;
        // margin-bottom: 120px;
        margin-left: 10vw;
        margin-top: 0;
        // top: 32px;
        h1 {
            margin: 1vh 1vw;
            margin-bottom: 0;
            padding: 5pt 0;
            font-size: 20pt;
        }
        p {
            text-align: center;
            margin: 0px;
            font-size: 12pt;
            font-weight: normal;            
            padding: 6vw 1vw;
            // padding-left: 3vw;
            // padding-right:0;
        }
    }

    .contact-form-container{
        position: relative;
        display: flex;
        flex-direction: column;
        align-content: center;
        text-align: center;
        padding-top: 2vw;
        width: 80vw;
        height: fit-content;
        margin: 0;
        margin-left: 10vw;
        margin-bottom: 160px;
        margin-top: 0;
        top: 32px;
        h1 {
            margin: 1vh 1vw;
            width: 75vw;
            margin-bottom: 0;
            padding: 5pt 0;
            font-size: 20pt;
        }
        p {
            margin: 0px;
            font-size: 1em;
            font-weight: normal;
            // padding: 6vw;
        }
    }

    .contact-label {
        display: flex;
        flex-direction: row;
        align-content: center;
        margin-left: 20vw;
        // padding: 0.5rem 0;
        input {
            display: flex;
            flex-direction: row;
        }
        textarea {
            display: flex;
            flex-direction: row;
        }
    } 
    .recaptcha{
        position: relative;
        right: 15vw;
    }

    .submit-button{
        width: 20vw;
    }
    
}
