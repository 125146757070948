.projects-wrapper{
    position: relative;
    margin-bottom: 5vh;
}
.project-container{
    height:50vh;
    width:100%;
    overflow:hidden;
    border-bottom: white solid 8px;
}
.active-label.project{
    bottom:0;
    top:35vh;
    left:32px;
    z-index: 1;
}
.active-label.project.right{
    left:59.5%;
    text-align: right;
    padding-right: 0;
    padding-left: 1rem;

}
.active-title.right{
    padding-left: 0;
    padding-right: 5pt;
}
.product-infobox.project{
    bottom:0;
    top:31vh;
    left: 59.5%;
    z-index: 1;
}
.product-infobox.project.active{
    top: 10vh;
}
.product-infobox.project.right{
    left: 32px;
}
.project-products{
    height: 100px;
    display: block;
    border: $goldColor 5px solid;
}
.cloth-project-slider.projects > div{
    min-width: initial;
    max-width: initial;
    width: 5456px;
}
.cloth-slide{
    width: 682px;
}
.cloth-project-slider.projects{
    position: relative;
}
.project-info-container{
    position: relative;
    top: -50vh;
}
.cloth-project-slide-container.projects{
    animation: moveProjectSlidesLeft $slide-timer linear infinite;
}
.cloth-project-slide-container.projects.right{
    animation: moveProjectSlidesRight $slide-timer linear infinite;
    right: 0;
    left:initial;
}
@media screen and (max-width: $breakpoint-hamburger) {
    .projects-wrapper{
        margin-bottom: 88px;
        top: 88px;
    }
    .product-infobox.project{
        top:30vh;
    }
    .product-infobox.project.active{
        top: 4.5vh;
    }
    .active-label.project{
        left: 16px;
    }
    .product-infobox.project{
        left: calc(59.5% - 6px);
    }
    .active-label.project.right{
        left:calc(59.5% - 16px);
    }
    .product-infobox.project.right{
        left: 16px;
    }
}
@media screen and (max-width: $breakpoint-tiny) {
    .active-label.project{
        top:20vh;
    }
    .product-infobox.project{
        top:10vh;
    }
    .product-infobox.project.active{
        top: -10vh;
    }
}