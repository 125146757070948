// web browser reset
* {
    margin: 0;
    padding: 0;
    font-family: 'Jaldi', sans-serif;
    // css for ghost testing elements, turn on to see all elements clearly
    // background: #000 !important;
    // color: #0f0 !important;
    // outline: solid #f00 1px !important;
}

// cloth default colours
$goldColor: #b3a26f;

$greyColor: #54514f;

$whiteColor: white;

// media break points
$breakpoint-hamburger: 960px;
$breakpoint-tiny: 350px;

// slide timer - used in projects
$slide-timer: 120s;

// Nav mixin
@mixin subLinks {
    font-family: 'Jaldi', sans-serif;
    padding: 0.5rem;
    display: table;
    border-bottom: 2px solid $greyColor;
    border-left: 2px solid $greyColor;
    min-width: 140px;
    color: $goldColor;
    text-decoration: none;
    background: none;
}

// footer mixin
@mixin background-opacity-footer {
    background-color:rgba($color: white, $alpha: 0.85);
}

@mixin footer-containers {
    background: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
}

@mixin footer-items {
    background: none;
    display: flex;
    align-self: flex-end;
    box-sizing: border-box;
}

@mixin linebreak {
    text-align: center;
    margin: 0 10px 0 10px;
    font-size: 1.2em;
    background: none;
}

@mixin media-960-box {
    font-size: 1.5vh;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
}

// Project slider 
@mixin project-slider {
    width: 100vw;
    height: 50vh;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    transform: translate3d(0, 0, 0);
}

@mixin project-slider-div {
    height: 50vh;
    min-width: 300vw;
    max-width: 7960px;
    position: absolute;
    transform: translate3d(0, 0, 0);
    top: 0;
}

@mixin project-slide-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@mixin project-slide {
    display: flex;
    white-space: nowrap;
    transition: ease 3000ms;
}

@mixin image-slide {
    display: flex;
    height: 50vh;
    min-width: 30vw;
    max-width: 796px;
}

@keyframes moveSlidesLeft {
    100% {
        transform: translateX(-70.2%);
    }
}
@keyframes moveSlidesRight {
    100% {
        transform: translateX(71.2%);
    }
}

// for the projects page
@keyframes moveProjectSlidesLeft {
    100% {
        transform: translateX(-62.5%);
    }
}
@keyframes moveProjectSlidesRight {
    100% {
        transform: translateX(62.5%);
    }
}

// text box grid formatting 

@mixin homeTextBoxes {
    background: rgba($color: $greyColor, $alpha: 0.85);
    width: 30vw;
    height: 25vh;
    position: relative;
    margin-left: 32px;
    display: grid;
    grid-template-columns: 50% 50%;
    font-family: 'Jaldi', sans-serif;
    color: white;
        h1 {
            display: flex;
            font-size: 2vw;
            background: none;
            margin: 32px 32px;
            border-top: 6px solid $goldColor;
            border-bottom: 6px solid $goldColor;
            align-items: center;
            justify-content: center;
        }
        p{
            display: flex;
            font-size: 1vw;
            background: none;
            margin: 32px 10px;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }
}

// home text box resizing for media query
@mixin mediaHomeTextGrid {
    width: 88vw;
    height: 100%;
    margin-left: 6%;
    margin-right: 6%;
    grid-template-columns: 36% 64%;
    h1 {
        margin: 32px 0px;
        font-size: 5vw;
    }
    p {
        margin: 32px;
        font-size: 3vw;
    }
}