// Project slide, can be imported into any page

.cloth-project-slide-container {
    @include project-slide-container;
    animation: moveSlidesLeft $slide-timer linear infinite;
}

.wi-project-slide-container {
    @include project-slide-container;
    animation: moveSlidesRight $slide-timer linear infinite;
}

.cloth-project-slide {
    @include project-slide;
}

.wi-project-slide {
    @include project-slide;
}

.cloth-slide {
    @include image-slide;   
    margin-bottom: 10px;   
}

.wi-slide {
    @include image-slide;
    margin-top: 10px;
}

// Home slide containers and children

.cloth-project-slider {
    @include project-slider;
    top: 0px;
}
.cloth-project-slider > div {
    @include project-slider-div;
    top: 0;
    left: 0;
}

.wi-project-slider {
    @include project-slider;
    top: 50vh;
}

.wi-project-slider > div {
    @include project-slider-div;
    bottom: 0;
    right: 0;
}

.Project-Slide {
    height: fit-content;
    position: relative;
}
.Project-Slide-Fixed {
    height: fit-content;
    position: fixed;
    top:0;
}

@media screen and (max-width: $breakpoint-hamburger) {
    .Project-Slide {
        top: -0px;
    }
}