.downloads-container {
    position: relative;
    margin-bottom: 360px;
    top: 88px;
}
.downloads-text{
    
    color: white;
    background: rgba($color: $greyColor, $alpha: 0.9);
    margin:32px;
    padding-top: 2vw;
    padding-bottom: 2vw;
    position: relative;
    p {
        margin-bottom: 10px;
        margin-left: 32px;
    }
    ul {
        list-style: none;
        margin-left: 2vw;
        font-size: 12pt;
        font-weight: bold;
    }
    li {
        border-bottom: 1px solid $goldColor;
        width: 20vw;
    }
    a {
        text-decoration: none;
        color: white;
        :hover {
            color: $goldColor;
        }
    }
    h1 {
        display: flex;
        font-size: 24pt;
        border-top: 6px solid $goldColor;
        border-bottom: 6px solid $goldColor;
        max-height: 50px;
        align-items: center;
        margin: 1vh 1vw;
    }
}

@media screen and (max-width: $breakpoint-hamburger) {
    .downloads-container{
        top: 88px;
        margin-bottom: calc(100vh - 250px);
        text-align: center;
        align-content: center;
    }
    .downloads-text {
        display: flex;
        flex-direction: column;
        align-content: center;
        
    }
    .download-list {
        padding-left: 18vw;
        li {
            width: 50vw;
        }
    }
}