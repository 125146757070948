.products-container {
    margin-bottom: 70vh;
}
.products-overview-container {
    margin-bottom: 25vh;
    position: relative;
    top: 88px;
}
.overview-title{
    margin-left: 64px;
    color: $goldColor;
    margin-top: 5vh;
}
.products-grid{
    display: grid;
    grid-template-columns: 17% 17% 17% 17% 17%;
    justify-content: center;
    position: relative;
    top: calc(50vh + 88px);
    column-gap: 2%;
    row-gap: 30px;
}
.products-grid.overview{
    top: 5vh;
}
.product-box{
    cursor: pointer;
    height: 17vw;
    text-decoration: none;
}
.product-image{
    width:100%;
}
.product-label{
    color: white;
    background: rgba($greyColor, 0.85);
    width: 61.8%;
    position: relative;
    bottom: 86px;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.product-title{
    padding-left: 5pt;
    border-top: $goldColor 5px solid;
    height: 2.5rem;
    width:100%;
    font-size: 9pt;
    border-bottom: $goldColor 5px solid;
}
.active-label{
    color: white;
    background: rgba($greyColor, 0.85);
    width: 35.9%;
    position: relative;
    bottom: 112px;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.active-title{
    padding-left: 5pt;
    border-top: $goldColor 3px solid;
    height: 2.5rem;
    align-items: center;
    font-size: 16pt;
    border-bottom: $goldColor 3px solid;
}
.product-infobox{
    width: 35.9%;
    position: relative;
    padding-left: 10px;
    bottom: 147px;
    left: calc(59.5% - 32px);
    background: rgba($greyColor, 0.65);
    border-bottom: 5px $greyColor solid;
    height: 30px;
    cursor: pointer;
    transition: 20ms;
}
.product-infobox.active{
    height:25vh;
    bottom: calc(117px + 25vh);
    text-align: left;
    overflow-x:hidden;
}
.infobox-arrow{
    position: absolute;
    height: 30px;
    left:calc(100% - 40px);
    transform: rotate(90deg);
}
.infobox-arrow.active{
    transform: rotate(270deg);
    display: block;
    // bottom: 0;
}
.product-description {
    color: white;
    font-size: 18pt;
    display: none;
}
.product-description.active{
    display: initial;
    margin-right: 30px;
}
.product-preview{
    height: calc(35vh - 88px);
    margin-left: 32px;
    z-index: 2;
    position: fixed;
}
.product-display{
    height: 50vh;
    width:100vw;
    position: relative;
    left: -32px;
    border-bottom: $greyColor 5px solid;
}
.back-container{
    position: relative;
    top: calc(50vh + 44px);
    margin-left: 32px;
    text-decoration: none;
    display: grid;
    grid-template-columns: 45px 20vw;
    align-items: center;
    width: calc(20vw + 45px);
}
.productsBack{
    color: $goldColor;
    font-weight: bold;
    font-size: 14pt;
}

@media screen and (max-width: $breakpoint-hamburger) {
    .products-grid{
        grid-template-columns: 42.4% 42.4%;
        top: 55vh;
        column-gap: 5vw;
        row-gap: 5vw;
    }
    .products-container{
        margin-bottom: 60vh;
    }
    .products-overview-container {
        margin-bottom: 35vh;
    }
    .overview-title{
        margin-left: 0;
        text-align: center;
    }
    .product-box{
        height:42.4vw;
    }
    .product-display{
        top:0;
        left:0
    }
    .product-preview{
        height: 35vh;
        margin-left: 0;
    }
    .product-label{
        padding-top: 5pt;
        bottom: 68px;
        padding-bottom: 5pt;
    }
    .product-title h2{
        font-size: 12pt;
    }
    .product-title.overview h2{
        font-size: 10pt;
    }
    .product-infobox{
        left: calc(64.1vw - 16px);
        bottom: 135px;
    }
    .product-description{
        font-size: 10pt;
    }
    .product-infobox.active{
        bottom: calc(105px + 30vh);
        height: 30vh;
    }
    .active-label{
        left:16px;
        bottom: 100px;
    }
    .active-title{
        font-size: 1rem;
        height: 3.5rem;
    }
    .back-container{
        top:53vh;
        grid-template-columns: 35px 30vw;
        column-gap: 10px;
        margin-left: 16px;
    }
    .productsBack{
        font-size: 10pt;
    }
    .backArrow{
        width:35px;
        height: auto;
    }
}
@media screen and (max-width: $breakpoint-hamburger) {
    .product-title.overview h2{
        font-size: 9pt;
    }
    .active-title{
        font-size: 0.8rem;
    }
}
@media screen and (max-width: $breakpoint-tiny) {
    .product-display{
        top: 38px;
    }
    .active-label{
        bottom: 70px;
    }
    .product-infobox{
        bottom: 105px;
    }
    .product-infobox.active{
        bottom: calc(75px + 30vh);
    }
    .back-container{
        top: 63vh;
    }
    .products-grid{
        top: 65vh;
    }

}