// about page styling

.about-container {
    position: relative;
    margin-bottom: 25vh;
}

.about-text-container {
    margin:32px;
    height: fit-content;
    margin-bottom: 15vh;
    position: relative;
    top: 120px;
    background: rgba($color: $greyColor, $alpha: 0.9);
    color: white;
    display: grid;
    grid-template-columns: 36% 64%;
    h1, p{
        background: none;
        margin: 4vw 0px  4vw 80px;
    }
    p {
        margin-right: 80px;
        font-size: 12pt;
        font-weight: bold;
    }
    h1 {
        display: flex;
        font-size: 24pt;
        border-top: 6px solid $goldColor;
        border-bottom: 6px solid $goldColor;
        max-height: 72pt;
        align-items: center;
    }

}
.policy-container{
    // height: 80vh;
    margin-bottom: 30vh;
    .policy-text{
    font-weight: normal;
    margin-right: 80px;
    font-size: 12pt;
    font-weight: bold;
    background: none;
        margin: 4vw 0px  4vw 80px;
}

}

@media screen and (max-width: $breakpoint-hamburger) {
    .about-container{
        top: 88px;
        bottom: 0;
        margin-bottom: 160px;
    }
    .about-text-container{
        display: inherit;
        padding-top: 2vw;
        height: fit-content;
        margin-bottom: 120px;
        margin-top: 0;
        top: 32px;
        h1 {
            margin: 1vh 1vw;
            margin-bottom: 0;
            padding: 5pt 0;
            font-size: 20pt;
        }
        p {
            margin: 0px;
            font-size: 12pt;
            font-weight: normal;
            padding: 6vw;
        }
    }
    .policy-container{
        height: fit-content;
        margin-top: 88px;
        .policy-text {
            margin: 0px;
            font-size: 12pt;
            font-weight: normal;
            padding: 6vw;
        }
    }
}